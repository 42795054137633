import VCalendar from "v-calendar"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VCalendar, {
    titlePosition: "center",
    masks: {
      title: "MMMM YYYY",
      navMonths: "MMM",
      weekdays: "WW",
      firstDayOfWeek: 2,
      input: "YYYY-MM-DD",
      inputDateTime: "YYYY-MM-DD h:mm A",
      inputDateTime24hr: "YYYY-MM-DD HH:mm",
    },
    datePicker: { popover: { visibility: "focus" }, inputDebounce: 500 },
  })
})
