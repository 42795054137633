import revive_payload_client_Ollksikfau from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_nDGDyGiWET from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5VbtvRfmBi from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import highchart_J5yQudteOk from "/vercel/path0/apps/zefire-spa/plugins/highchart.ts";
import check_outdated_build_client_AwX4AX7Xj6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_F5Roq8lpDV from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@3.29.4_typescript@5.4.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/zefire-spa/.nuxt/components.plugin.mjs";
import prefetch_client_XOXsxHnsfq from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import modals_9P01OGqupu from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.14.2_axios@1.4.0_nprogress@0.2.0_nuxt@3.11.1_rollup@3.29.4_vite@5.2.3_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_tLh4C1dSNx from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.14.2_axios@1.4.0_nprogress@0.2.0_nuxt@3.11.1_rollup@3.29.4_vite@5.2.3_vue@3.4.21/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_J3WhyjqvPK from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.3_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_Nj8Ld712IF from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@3.29.4_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kVIgYaJpJ0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_@types+node@18.19.26_@unocss+reset@0.58.6_axios@1.4.0_eslint@8.57.0_floating-vue@_fvvmuv5ezg3ttluyclhbj46z6a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/vercel/path0/apps/zefire-spa/plugins/auth.ts";
import custom_directives_gtEpGpM7lF from "/vercel/path0/apps/zefire-spa/plugins/custom-directives.ts";
import nprogress_OPYCKjD1DE from "/vercel/path0/apps/zefire-spa/plugins/nprogress.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/apps/zefire-spa/plugins/sentry.ts";
import tippy_xFBYqfAPCu from "/vercel/path0/apps/zefire-spa/plugins/tippy.ts";
import v_calendar_dxaVzst4iS from "/vercel/path0/apps/zefire-spa/plugins/v-calendar.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/zefire-spa/plugins/vue-query.ts";
export default [
  revive_payload_client_Ollksikfau,
  unhead_nDGDyGiWET,
  router_5VbtvRfmBi,
  highchart_J5yQudteOk,
  check_outdated_build_client_AwX4AX7Xj6,
  plugin_vue3_F5Roq8lpDV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XOXsxHnsfq,
  modals_9P01OGqupu,
  colors_tLh4C1dSNx,
  plugin_client_J3WhyjqvPK,
  i18n_Nj8Ld712IF,
  chunk_reload_client_kVIgYaJpJ0,
  auth_vT9JWWT9pN,
  custom_directives_gtEpGpM7lF,
  nprogress_OPYCKjD1DE,
  sentry_3AyO8nEfhE,
  tippy_xFBYqfAPCu,
  v_calendar_dxaVzst4iS,
  vue_query_wrmMkNpEpe
]