import type { BookType } from "xlsx"
import { utils as xlsxUtils, write as xlsxWrite, writeFile as xlsxWriteFile } from "xlsx"
import * as dt from "date-fns"
import { Unit } from "#imports"
import type { NoteTagSchema } from "#imports"

/**
 * A function that create convert array of object into an excel file and make the browser download it.
 * @param {string} fileName - The name that the excel file should take.
 * @param {BookType} type - The extension of the file (xsl,xslx,csv,...)
 * @param {Array<any>} data -  The data to convert to excel
 */
export function dataToExcel(fileName: string, type: BookType, data: any[]): void {
  const now = dt.format(new Date(), "MM/dd-HH:mm")
  const wb = xlsxUtils.book_new()
  const file = xlsxUtils.json_to_sheet(data)
  xlsxUtils.book_append_sheet(wb, file, fileName)
  xlsxWrite(wb, { bookType: type, type: "binary" })
  xlsxWriteFile(wb, `${fileName}-${now}.${type}`)
}

interface DataMapper {
  model: string
  field?: string
  headerName: string
  format?: (value: any) => any
}

export function formatDataToExcel(
  filterObject: "Site" | "Contract" | "Invoice" | "Event" | "Note",
  items: any[],
  i18n: ReturnType<typeof useI18n>,
): any[] {
  const sepArray = ", "

  if (filterObject === "Invoice") {
    items = items.map((invoice) => computeTotalLinesOnInvoice(invoice))
  }

  const formatters = {
    date: formatDate,
    fullDate: (i: string | Date) => formatDate(i, true),
    unitValue: (fromUnit: Unit, toUnit?: Unit) => (value: number | null | undefined) => {
      const convRate = toUnit ? getUnitConversionRate(fromUnit, toUnit) : 1
      return typeof value === "number" ? i18n.n(value * convRate, toUnit || fromUnit) : ""
    },
    sites: (s: string[]) => {
      return ld.map(s, (site: { name: string; uuid: string }) => site.name).join(sepArray)
    },
    contract_type: deslugContractType,
    contract_status: (values: string[]) =>
      values.map((value) => i18n.t(`site.contract_status.${value}`)).join(sepArray),
    dp_codes: (values: string[]) => values.join(sepArray),
    availability_status: (values: (typeof PastillaSchemaShort)[]) => {
      const result = ld(values).find({ name: "availability" })
      return result ? i18n.t(`metrics.status.${result.status}`) : ""
    },
    availability_status_change: (values: (typeof PastillaSchemaShort)[]) => {
      const result = ld(values).find({ name: "availability" })
      return result ? formatDate(result.last_status_change, true) : ""
    },
    upperCase: (value: string) => value.toUpperCase(),
    duration: (item: any) => {
      return dt.formatDuration(dt.intervalToDuration({ start: item.start, end: item.end }))
    },
    tags: (values: NoteTagSchema[]) => {
      const result = values.map((e) => e.name)
      return result.join(sepArray)
    },
  }

  const dataMapperContract: DataMapper[] = [
    { model: "Contract", field: "site.name", headerName: i18n.t("contract.header.site_name") },
    {
      model: "Contract",
      field: "site.assetowner.name",
      headerName: i18n.t("title.asset_owner"),
      format: formatters.upperCase,
    },
    {
      model: "Contract",
      field: "purchase_order",
      headerName: i18n.t("contract.header.id_purchase"),
    },
    {
      model: "Contract",
      field: "site.machine_type",
      headerName: i18n.t("contract.header.techno"),
      format: (value) => i18n.t(`machine_type.${value}`),
    },
    {
      model: "Contract",
      field: "start",
      headerName: i18n.t("contract.header.start"),
      format: formatters.date,
    },
    {
      model: "Contract",
      field: "end",
      headerName: i18n.t("contract.header.end"),
      format: formatters.date,
    },
    {
      model: "Contract",
      field: "site.capacity",
      headerName: i18n.t("contract.header.site_capacity"),
    },
    {
      model: "Contract",
      field: "price_mechanism",
      headerName: i18n.t("contract.header.price_mecha"),
      format: (value) => i18n.t(`site.contract_status.${value}`),
    },
    {
      model: "Contract",
      field: "type.name",
      headerName: i18n.t("contract.header.type"),
      format: formatters.contract_type,
    },
    { model: "Contract", field: "offtaker.name", headerName: i18n.t("contract.header.offtaker") },
    {
      model: "Contract",
      field: "invoice_source",
      headerName: i18n.t("contract.header.invoice_source"),
      format: (value) => i18n.t(`invoice.source.${value}`),
    },
    { model: "Contract", field: "site.p50", headerName: i18n.t("contract.header.p50") },
    { model: "Contract", field: "tarif", headerName: i18n.t("contract.header.tarif") },
    { model: "Contract", field: "fix_price", headerName: i18n.t("contract.header.fix_price") },
    {
      model: "Contract",
      field: "management_fee",
      headerName: i18n.t("contract.header.management_fee"),
    },
  ]

  const dataMapperSite: DataMapper[] = [
    {
      model: "Site",
      field: "name",
      headerName: i18n.t("contract.header.site_name"),
    },
    {
      model: "Site",
      field: "machine_type",
      headerName: i18n.t("contract.header.techno"),
      format: (value) => i18n.t(`machine_type.${value}`),
    },
    { model: "Site", field: "region.name", headerName: i18n.t("contract.header.region") },
    { model: "Site", field: "capacity", headerName: i18n.t("contract.header.site_capacity") },
    { model: "Site", field: "p50", headerName: i18n.t("contract.header.p50") },
    {
      model: "Site",
      field: "commissioning",
      headerName: i18n.t("contract.header.commissioning"),
      format: formatters.date,
    },
    { model: "Site", field: "assetowner.name", headerName: i18n.t("contract.header.spv") },
    { model: "Site", field: "is_external", headerName: i18n.t("company.header.external") },
    { model: "Site", field: "latitude", headerName: i18n.t("contract.header.latitude") },
    { model: "Site", field: "longitude", headerName: i18n.t("contract.header.longitude") },
    {
      model: "Site",
      field: "contract_status",
      headerName: i18n.t("contract.header.current_status"),
      format: formatters.contract_status,
    },
    {
      model: "Site",
      field: "portfolio.name",
      headerName: i18n.t("contract.header.portfolio_name"),
    },
    { model: "Site", field: "external_id", headerName: i18n.t("contract.header.external_id") },
    {
      model: "Site",
      field: "dp_codes",
      headerName: i18n.t("contract.header.dp_codes"),
      format: formatters.dp_codes,
    },
    {
      model: "Site",
      field: "dp_references",
      headerName: i18n.t("contract.header.dp_references"),
      format: formatters.dp_codes,
    },
    {
      model: "Site",
      field: "cached_pastilla_values",
      headerName: i18n.t("contract.header.availability_status"),
      format: formatters.availability_status,
    },
    {
      model: "Site",
      field: "cached_pastilla_values",
      headerName: i18n.t("contract.header.availability_status_change"),
      format: formatters.availability_status_change,
    },
  ]

  const dataMapperInvoice: DataMapper[] = [
    { model: "Invoice", field: "site.name", headerName: i18n.t("invoice.header.site_name") },
    {
      model: "Invoice",
      field: "site.assetowner.name",
      headerName: i18n.t("invoice.header.asset_owner"),
      format: formatters.upperCase,
    },
    {
      model: "Invoice",
      field: "site.external_id",
      headerName: i18n.t("invoice.header.external_id"),
    },
    {
      model: "Invoice",
      field: "contract.purchase_order",
      headerName: i18n.t("invoice.header.contract_id"),
    },
    { model: "Invoice", field: "start", headerName: i18n.t("time.start"), format: formatters.date },
    { model: "Invoice", field: "end", headerName: i18n.t("time.end"), format: formatters.date },
    {
      model: "Invoice",
      headerName: i18n.t("time.period"),
      format: (item) => {
        const fmt = "yyyy-MM-dd"
        const start = dt.format(toDateTZ(item.start), fmt)
        const end = dt.format(toDateTZ(item.end), fmt)
        return `${start} / ${end}`
      },
    },
    {
      model: "Invoice",
      field: "status",
      headerName: i18n.t("invoice.header.status"),
      format: (value) => i18n.t(`invoice.status.${value}`),
    },
    {
      model: "Invoice",
      field: "publication_id",
      headerName: i18n.t("invoice.header.publication_id"),
    },
    {
      model: "Invoice",
      field: "bestof_total_line.price",
      headerName: i18n.t("invoice.header.average_price"),
      format: Number,
    },
    {
      model: "Invoice",
      field: "bestof_total_line.amount",
      headerName: i18n.t("invoice.header.amount"),
      format: Number,
    },
    {
      model: "Invoice",
      field: "bestof_total_line.quantity",
      headerName: i18n.t("invoice.header.quantity"),
      format: Number,
    },
    {
      model: "Invoice",
      field: "total_line.price",
      headerName: i18n.t("invoice.header.average_price_computed"),
      format: Number,
    },
    {
      model: "Invoice",
      field: "total_line.amount",
      headerName: i18n.t("invoice.header.amount_computed"),
    },
    {
      model: "Invoice",
      field: "total_line.quantity",
      headerName: i18n.t("invoice.header.quantity_computed"),
      format: Number,
    },
    {
      model: "Invoice",
      field: "computation_date",
      headerName: i18n.t("invoice.header.computation_date"),
      format: formatters.date,
    },
    {
      model: "Invoice",
      field: "publication_date",
      headerName: i18n.t("invoice.header.submission_date"),
      format: formatters.date,
    },
  ]
  const dataMapperEvent: DataMapper[] = [
    { model: "Event", field: "site.external_id", headerName: i18n.t("calendar.event.external_id") },
    { model: "Event", field: "site.name", headerName: i18n.t("title.name") },
    { model: "Event", field: "site.portfolio.name", headerName: i18n.t("title.portfolio") },
    { model: "Event", field: "site.assetowner.name", headerName: i18n.t("title.asset_owner") },
    {
      model: "Event",
      field: "start",
      headerName: i18n.t("time.unavailability_start"),
      format: formatters.fullDate,
    },
    {
      model: "Event",
      field: "end",
      headerName: i18n.t("time.unavailability_end"),
      format: formatters.fullDate,
    },
    { model: "Event", headerName: i18n.t("time.duration"), format: formatters.duration },
    {
      model: "Event",
      field: "event_type",
      headerName: i18n.t("title.type"),
      format: (value) => i18n.t(`event.type.${value}`),
    },
    {
      model: "Event",
      field: "losses.revenue_loss",
      headerName: i18n.t("calendar.event.revenue_losses"),
      format: formatters.unitValue(Unit.enum.eur),
    },
    {
      model: "Event",
      field: "losses.energy_loss",
      headerName: i18n.t("calendar.event.energy_losses"),
      format: formatters.unitValue(Unit.enum.MWh),
    },
    {
      model: "Event",
      field: "description",
      headerName: i18n.t("title.description"),
    },
    {
      model: "Event",
      field: "is_external",
      headerName: i18n.t("title.is_external"),
    },
    {
      model: "Event",
      field: "cause",
      headerName: i18n.t("title.cause"),
      format: (value) => (R.isNonNullish(value) ? i18n.t(`event.cause.${value}`) : undefined),
    },
  ]

  const dataMapperNote: DataMapper[] = [
    {
      model: "Note",
      field: "target_model",
      headerName: i18n.t("note.header.target_model"),
      format: (value) => i18n.t(`object.${value}`),
    },
    { model: "Note", field: "target_name", headerName: i18n.t("note.header.target_name") },
    {
      model: "Note",
      field: "start",
      headerName: i18n.t("time.start"),
      format: formatters.date,
    },
    {
      model: "Note",
      field: "end",
      headerName: i18n.t("time.end"),
      format: formatters.date,
    },
    { model: "Note", field: "body", headerName: i18n.t("note.header.body") },
    {
      model: "Note",
      field: "level",
      headerName: i18n.t("note.header.level"),
      format: (value) => i18n.t(`note.level.${value}`),
    },
    { model: "Note", field: "pinned", headerName: i18n.t("note.header.pinned") },
    {
      model: "Note",
      field: "tags",
      headerName: i18n.t("note.header.tags"),
      format: formatters.tags,
    },
    { model: "Note", field: "archived", headerName: i18n.t("note.header.archived") },
    { model: "Note", field: "created_by", headerName: i18n.t("note.header.created_by") },
    {
      model: "Note",
      field: "creation_date",
      headerName: i18n.t("note.header.creation_date"),
      format: formatters.date,
    },
    { model: "Note", field: "updated_by", headerName: i18n.t("note.header.updated_by") },
    {
      model: "Note",
      field: "update_date",
      headerName: i18n.t("note.header.update_date"),
      format: formatters.date,
    },
  ]

  const dataMapper: DataMapper[] = [
    ...dataMapperContract,
    ...dataMapperSite,
    ...dataMapperInvoice,
    ...dataMapperEvent,
    ...dataMapperNote,
  ]
  const excelData: any[] = []
  const filteredDataMapper: DataMapper[] = dataMapper.filter((e) => e.model === filterObject)

  items.forEach((data) => {
    const row: any = {}
    filteredDataMapper.forEach((el: DataMapper) => {
      let value: any = el.field ? ld.get(data, el.field) : data // if no field is passed we use whole item

      if (R.isString(value) || el.format) {
        const fnFmt = el.format || ((value) => value)
        value = fnFmt(value)
      }

      row[el.headerName] = value
    })
    excelData.push(row)
  })
  return excelData
}
