import { ZodError } from "zod"
import { AxiosError } from "axios"

import type { DehydratedState, VueQueryPluginOptions } from "@tanstack/vue-query"
import { QueryClient, VueQueryPlugin, dehydrate, hydrate } from "@tanstack/vue-query"

import { useState } from "#app"

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>("vue-query")

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: (failureCount: number, error: any): boolean => {
          if (error instanceof ZodError) {
            return false
          } else if (error instanceof AxiosError) {
            if ([404, 500].includes(error.response?.status as any)) {
              return false
            }
          }
          return failureCount < 3
        },
      },
    },
  })
  const options: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, options)

  if (import.meta.server) {
    nuxt.hooks.hook("app:rendered", () => {
      vueQueryState.value = dehydrate(queryClient)
    })
  }

  if (import.meta.client) {
    nuxt.hooks.hook("app:created", () => {
      hydrate(queryClient, vueQueryState.value)
    })
  }
})
