import { vElementHover, vOnClickOutside } from "@vueuse/components"

import { createPopper } from "@popperjs/core"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("element-hover", vElementHover)
  nuxtApp.vueApp.directive("click-outside", vOnClickOutside)
  nuxtApp.vueApp.directive("autofocus", {
    mounted(el, { value }) {
      if (value === true || value === undefined) {
        el.focus()
      }
    },
  })

  nuxtApp.vueApp.directive("overflow", {
    created(el) {
      const baseStyle = " text-overflow:ellipsis; white-space:nowrap; overflow: hidden"
      el.style += baseStyle

      const activeStyle = `
        position: absolute;
        color: white;
        background-color: #374151;
        overflow: visible;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border-radius: 0.25rem;
      `

      let popperInstance: undefined | ReturnType<typeof createPopper>

      const elementId = "overflow-directive"

      function close() {
        if (popperInstance) {
          popperInstance.destroy()
        }
        document.getElementById(elementId)?.remove()
      }

      el.addEventListener("mouseenter", (evt: any) => {
        const targetEl = evt.target
        const isOverflowing =
          targetEl.clientWidth < targetEl.scrollWidth ||
          targetEl.clientHeight < targetEl.scrollHeight

        if (isOverflowing) {
          // Prepending a new div with an absolute position to display the data because of flex container issues.
          const popperEl = document.createElement("div")
          popperEl.id = elementId

          popperInstance = createPopper(el, popperEl, { placement: "top" })
          popperEl.setAttribute("style", activeStyle)

          popperEl.innerHTML = el.innerHTML // same content
          el.prepend(popperEl)

          // Delete also when mouseenter the popup
          popperEl.addEventListener("mouseenter", close)
        }
      })

      el.addEventListener("mouseleave", close)
    },
  })
})
