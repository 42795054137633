import { GenericError as Auth0GenericError } from "@auth0/auth0-spa-js"
import * as Sentry from "@sentry/vue"
import type { Auth0VueClient } from "@auth0/auth0-vue"
import { useMainStore } from "~/composables/main-store"
import { AuthRoutePaths, handleCallback, login, logout } from "~/plugins/auth"

function postSetupAccessToken(auth0: Auth0VueClient, access_token: string) {
  const decoded = JSON.parse(window.atob(access_token.split(".")[1]))
  useMainStore().token = {
    permissions: decoded.permissions,
    raw: access_token,
    decoded,
  }

  // Set sentry user
  const auth0User = auth0.user.value!
  Sentry.setUser({
    email: auth0User.email,
    org_id: auth0User.org_id,
    permissions: decoded.permissions,
  })
}

export default defineNuxtRouteMiddleware(async (to) => {
  if (!import.meta.client) {
    return
  }

  const config = useRuntimeConfig()
  const auth0 = useAuth0()
  const store = useMainStore()

  async function retrieveAccessToken() {
    // From auth0 doc:
    // If there's a valid token stored and it has more than 60 seconds
    // remaining before expiration, return the token.
    const cached_access_token = await auth0.getAccessTokenSilently({ cacheMode: "cache-only" })
    if (cached_access_token) {
      if (R.isEmpty(store.token)) {
        postSetupAccessToken(auth0, cached_access_token)
      }
      return cached_access_token
    }

    const access_token = await auth0.getAccessTokenSilently({ cacheMode: "on" })

    postSetupAccessToken(auth0, access_token)

    return access_token
  }

  if (!R.values(config.public.auth0).every((e) => R.isTruthy(e))) {
    useApiClient() // instanciate axios without access_token as local dev
  } else {
    // Handle Authentication routes
    switch (to.path) {
      case AuthRoutePaths.login:
        await login(auth0, to)
        return

      case AuthRoutePaths.logout:
        await logout(auth0)
        return AuthRoutePaths.login

      case AuthRoutePaths.callback:
        try {
          return await handleCallback(auth0)
        } catch (error) {
          // Can't do `if (error instanceof Auth0GenericError)` due to:
          // https://github.com/microsoft/TypeScript/issues/22585
          if (error instanceof Error && "message" in error) {
            if (error.message === "Invalid state") {
              console.warn("Wrong state in handle callback, redirecting to new login")
              return AuthRoutePaths.login
            }
          }
          throw error
        }

      case "/error": // public route
        return

      default:
        try {
          await retrieveAccessToken()
        } catch (error) {
          if (error instanceof Error && "message" in error) {
            const auth0Error = error as Auth0GenericError
            const requiresLoginErrors = [
              "login_required", // Session expired
              "missing_refresh_token", // Missing refresh token
              "invalid_grant", // Unknown or invalid refresh token
            ]
            if (requiresLoginErrors.includes(auth0Error.error)) {
              await login(auth0, to)
            } else {
              try {
                const msg = `Error spotted from auth0:
                name=${auth0Error.name}
                message=${auth0Error.message}
                error=${auth0Error.error}
                error_description=${auth0Error.error_description}
              `
                console.error(msg)
              } catch {
                console.log("Error from auth0", auth0Error)
              }
              await login(auth0, to)
            }
          } else {
            throw error
          }
        }

        useApiClient(async () => {
          try {
            return await retrieveAccessToken()
          } catch (error) {
            if ((error as Auth0GenericError).error === "login_required") {
              console.warn("Failed to retrieve access token silently. Declaring session idle.")
              store.isIdleSession = true
              throw error
            } else {
              throw error
            }
          }
        })
    }
  }

  // Fetch user infos from zefire
  if (!store.user && !__HISTOIRE_CONTEXT__ && !__VITEST_CONTEXT__) {
    await store.fetchZefireUser()
  }
})
