import ld from "lodash"
import { Unit } from "./schemas"

// _______ Unit ______

export const unitFractionDigitsMap: { [key: string]: { [key: string]: number } } = {
  // Energy
  [Unit.enum.TWh]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.GWh]: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  [Unit.enum.MWh]: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  [Unit.enum.kWh]: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  //
  // Power
  [Unit.enum.TW]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.GW]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.MW]: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  [Unit.enum.kW]: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  //
  // Currency
  [Unit.enum.ceur]: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  "c€": { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  [Unit.enum.eur]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "€": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.keur]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "k€": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.Meur]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "M€": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.Geur]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "B€": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  //
  // prices
  [Unit.enum["eur/kW"]]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum["eur/dimensionless"]]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum["eur/kWh"]]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "€/kWh": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum["eur/MWh"]]: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  "€/MWh": { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum["ceur/kWh"]]: { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  "c€/kWh": { minimumFractionDigits: 3, maximumFractionDigits: 3 },
  //
  // distance
  [Unit.enum.m]: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  // indexes:
  l_index: { minimumFractionDigits: 5, maximumFractionDigits: 5 },
  // coordinates
  latlng: { minimumFractionDigits: 0, maximumFractionDigits: 3 },
  // dimensionless
  [Unit.enum.dimensionless]: {},
  "%": { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  int: { minimumFractionDigits: 0, maximumFractionDigits: 0 },
  oneDecimal: { minimumFractionDigits: 1, maximumFractionDigits: 1 },
  twoDecimal: { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  [Unit.enum.coef_l]: { minimumFractionDigits: 5, maximumFractionDigits: 5 },
}

const mapUnitDimension: { [key: string]: Unit[] } = {
  energy: [Unit.enum.TWh, Unit.enum.GWh, Unit.enum.MWh, Unit.enum.kWh],
  power: [Unit.enum.TW, Unit.enum.GW, Unit.enum.MW, Unit.enum.kW],
  currency: [Unit.enum.Geur, Unit.enum.Meur, Unit.enum.keur, Unit.enum.eur, Unit.enum.ceur],
  price: [
    Unit.enum["ceur/kWh"],
    Unit.enum["eur/kWh"],
    Unit.enum["eur/MWh"],
    Unit.enum["eur/dimensionless"],
  ],
  price_per_capa: [Unit.enum["eur/kW"]],
}

export function getUnitConversionRate(from: Unit, to: Unit): number {
  if (from === to) {
    return 1
  }

  let ratio: number

  const errMsg = `Can't convert from ${from} to ${to}`

  const isDimensionConvert = (values: Unit[]): boolean => {
    if (values.includes(from)) {
      if (!values.includes(to)) {
        throw errMsg
      } else {
        return true
      }
    }
    return false
  }

  const getRatio = (values: Unit[]): number => {
    const indexesDist = ld.indexOf(values, to) - ld.indexOf(values, from)
    return 10 ** (3 * indexesDist)
  }

  if (isDimensionConvert(mapUnitDimension.energy)) {
    ratio = getRatio(mapUnitDimension.energy)
  } else if (isDimensionConvert(mapUnitDimension.power)) {
    ratio = getRatio(mapUnitDimension.power)
  } else if (isDimensionConvert(mapUnitDimension.price_per_capa)) {
    ratio = getRatio(mapUnitDimension.price_per_capa)
  } else if (isDimensionConvert(mapUnitDimension.currency)) {
    ratio = getRatio(mapUnitDimension.currency)
    if (from === Unit.enum.ceur) {
      ratio = ratio * 10
    }
    if (to === Unit.enum.ceur) {
      ratio = ratio / 10
    }
  } else if (isDimensionConvert(mapUnitDimension.price)) {
    ratio = getRatio(mapUnitDimension.price)
    if (from === Unit.enum["ceur/kWh"]) {
      ratio = ratio / 1000 / 100
    }
    if (to === Unit.enum["ceur/kWh"]) {
      ratio = ratio * 1000 * 100
    }
  } else if (isDimensionConvert([Unit.enum.dimensionless, Unit.enum["%"]])) {
    if (from === Unit.enum.dimensionless && to === Unit.enum["%"]) {
      return (ratio = 100)
    } else {
      ratio = 1
    }
  } else {
    throw new Error(`Unit conversion from ${from} to ${to} is not yet implemented`)
  }

  return ratio
}

export function autoConvertQuantity(value: number, fromUnit: Unit): { value: number; unit: Unit } {
  // https://stackoverflow.com/questions/14879691/get-number-of-digits-with-javascript
  function numDigits(x: number): number {
    return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1
  }

  const nFigures = numDigits(value)
  let toUnit: Unit = fromUnit

  if (nFigures >= 4) {
    const idxDelta = Math.floor((nFigures - 1) / 3)
    let units: Unit[] = []
    if (mapUnitDimension.energy.includes(fromUnit)) {
      units = mapUnitDimension.energy
    } else if (mapUnitDimension.power.includes(fromUnit)) {
      units = mapUnitDimension.power
    } else if (mapUnitDimension.currency.includes(fromUnit)) {
      units = mapUnitDimension.currency
    }

    if (units.length) {
      const idx = units.indexOf(fromUnit)
      const newIdx = idx - idxDelta
      if (newIdx >= 0) {
        toUnit = units[newIdx]
      }
    }
  }

  const ratio = getUnitConversionRate(fromUnit, toUnit)
  return { value: value * ratio, unit: toUnit }
}
