import { defineStore } from "pinia"
import { v4 as uuidv4 } from "uuid"
import type { BNotif } from "~/utils/models"
import type { ProducerCompanyKind, Scope, User as ZefireUser } from "~/utils/schemas"

interface MainStoreInterface {
  user: Readonly<ZefireUser>

  token: Readonly<{
    permissions: Scope[]
    raw: string
    decoded: Record<string, any>
  }>
  isIdleSession: boolean

  showNotifSideModal: boolean // whether sidebar modal overlay is shown
  notifications: BNotif[]
  gmapCenter?: { lat: number; lng: number }
  isViewLoading: boolean
}

export const useMainStore = defineStore("main", {
  state: () =>
    ({
      user: undefined as any,
      token: {} as any,
      isIdleSession: false,
      notifications: [],
      showNotifSideModal: false,
      // WARNING Vue reactive function is called on the initial state,
      // hence we absolutely need to pass every attributes of the interface
      // even though gmapCenter is optional as we need the reactivity on it
      gmapCenter: undefined,
      isViewLoading: false,
      pageActions: ref([]),
    }) as MainStoreInterface,
  getters: {
    userCompanyQuery(state): { uuid: string; model: ProducerCompanyKind } {
      const model = state.user.company.kind as ProducerCompanyKind
      const uuid = state.user.company.uuid as string
      return { model, uuid }
    },
  },
  actions: {
    async fetchZefireUser() {
      this.user = await useApiClient().get_me()
    },
    //
    addNotification<T>(
      notification: Pick<BNotif<T>, "title" | "text" | "level"> &
        Partial<Omit<BNotif<T>, "title" | "text" | "level">>,
    ) {
      const defaultValues = {
        uuid: uuidv4() as string,
        createdAt: new Date(),
        isActive: true,
        ttl: 3000,
        isRead: false,
        actions: undefined,
        icon: "",
        data: undefined,
      }
      this.notifications.push({ ...defaultValues, ...notification })
    },
    toggleNotifIsRead(notification_uuid: string) {
      const idx = ld.findIndex(this.notifications, (o: any) => o.uuid === notification_uuid)
      if (idx !== -1) {
        this.notifications[idx].isRead = !this.notifications[idx].isRead
      }
    },
    deleteAllNotifications() {
      this.notifications = []
    },
  },
})
