import { downloadZip } from "client-zip"
import type { AxiosResponse } from "axios"
import { InvoiceSchemaShort } from "#imports"

export function createDownloadLink(blob: Blob, filename: string): void {
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  link.remove()
  URL.revokeObjectURL(link.href)
}

export function filenameFromResponse(r: AxiosResponse): string {
  const contentDisposition = r.headers["content-disposition"]

  // https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition

  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  if (utf8FilenameRegex.test(contentDisposition)) {
    const filename_utf8 = utf8FilenameRegex.exec(contentDisposition)![1]
    return decodeURIComponent(filename_utf8)
  } else {
    const matches = asciiFilenameRegex.exec(contentDisposition)
    if (matches != null && matches[2]) {
      return matches[2]
    }
  }

  throw new Error(`Could not find filename in content-disposition: ${contentDisposition}`)
}

// Regarding files download / upload,
// Waiting for https://github.com/ecyrbe/zodios/issues/426
// So we don't have to code this ourself

async function _dispatchFileResponses(responses: AxiosResponse[]) {
  if (responses.length === 1) {
    const resp = responses[0]
    createDownloadLink(resp.data, filenameFromResponse(resp))
  } else {
    const files: File[] = R.map(
      responses,
      (resp) => new File([resp.data], filenameFromResponse(resp)),
    )
    const blob = await downloadZip(files).blob()
    createDownloadLink(blob, "files.zip")
  }
}

async function _downloadDocumentContent(uuid: string) {
  const axios = useAxiosInstance()
  return await axios.get(
    `api/document/${uuid}/content`,
    // Why we need "blob": https://stackoverflow.com/a/60461828
    { responseType: "blob" },
  )
}

export async function downloadAndOpenPDF(uuid: string) {
  const resp = await _downloadDocumentContent(uuid)
  const blob = new Blob([resp.data], { type: "application/pdf" })
  const pdfUrl = URL.createObjectURL(blob)
  const win = window.open(pdfUrl, "_blank")
  win?.focus()
}

export async function downloadMultipleDocuments(uuids: string[]) {
  const responses = await Promise.all(uuids.map((uuid) => _downloadDocumentContent(uuid)))
  await _dispatchFileResponses(responses)
}

export async function downloadInvoicesPDF(uuids: InvoiceSchemaShort["uuid"][]) {
  const axios = useAxiosInstance()
  const responses = await Promise.all(
    uuids.map((uuid) => axios.get(`api/invoices/${uuid}/document`, { responseType: "blob" })),
  )
  await _dispatchFileResponses(responses)
}

export async function downloadInvoicePDF(uuid: InvoiceSchemaShort["uuid"]): Promise<Blob> {
  const axios = useAxiosInstance()
  const resp = await axios.get(`api/invoices/${uuid}/document`, { responseType: "blob" })
  const blob = new Blob([resp.data], { type: "application/pdf" })
  return blob
}

export async function downloadInvoicesExcel(uuids: InvoiceSchemaShort["uuid"][]) {
  const axios = useAxiosInstance()
  const responses = await Promise.all(
    uuids.map((uuid) => axios.get(`api/invoices/${uuid}/excel`, { responseType: "blob" })),
  )
  await _dispatchFileResponses(responses)
}
