<script lang="ts" setup>
import { login } from "~/plugins/auth"
import type { DropdownItem } from "#ui/types"

const route = useRoute()

const { t } = useI18n()

const title = computed<string>(() =>
  route.meta.title ? t(`suptitle.${route.meta.title}`) || "" : "",
)
const subtitle_formatter = computed(
  () => (value: string) =>
    R.isTruthy(route.meta.translate_subtitle) ? t(`subtitle.${value}`) : value,
)
const subtitle = computed<string | undefined>(() =>
  route.meta.subtitle ? subtitle_formatter.value(route.meta.subtitle) : undefined,
)
const seoTitle = computed<string>(() => {
  if (route.meta.seoTitle) {
    return t(`seotitle.${route.meta.seoTitle}`)
  }
  if (subtitle.value) {
    return `${title.value} ${subtitle.value}`
  }
  return title.value
})

const showTopBar = computed(() => route.meta.showTopBar || route.meta.showTopBar === undefined)

watchEffect(() => {
  if (seoTitle.value) {
    useSeoMeta({
      title: () => seoTitle.value,
    })
  }
})

const auth0 = useAuth0()
const mainStore = useMainStore()

// handle main ref
const mainContainerRef: Ref<HTMLElement | null> = ref(null)
provide("mainContainerRef", mainContainerRef)

// define page actions
const pageActions = computed<DropdownItem[][]>(() => {
  const defs = useActionStore().actionDefs
  const actives = route.meta.activeActionKinds || []

  const actions: DropdownItem[][] = R.values(R.pick(defs, actives)).filter(
    (items) => items.length > 0,
  )
  const dropdown_items = R.pipe(
    actions,
    R.map((subgroup) =>
      R.map(subgroup, (item) => ({
        label: t(`components.management.${item.label}`),
        ...R.omit(item, ["label"]),
      })),
    ),
  )

  return dropdown_items
})
</script>

<template>
  <div class="flex h-screen w-screen flex-row">
    <AppSidebar />
    <UContainer
      ref="mainContainerRef"
      class="h-screen w-full max-w-full overflow-y-auto overflow-x-hidden"
    >
      <header
        class="sticky top-0 z-40 -mx-1 flex h-16 flex-row items-center justify-between bg-white px-1 text-2xl font-semibold"
        :class="showTopBar ? '' : 'hidden'"
      >
        <div class="inline-flex items-center space-x-2 text-rnxblue-500">
          <h1>{{ title }}</h1>
          <h2
            v-if="subtitle"
            class="max-w-lg overflow-hidden truncate whitespace-nowrap text-gray-700 xl:max-w-2xl 3xl:max-w-5xl"
          >
            <span class="pr-2 text-gray-500">:</span>
            <span>{{ subtitle }}</span>
          </h2>
          <div id="teleport-layout-subtitle" />
        </div>

        <div class="flex flex-row items-center space-x-4">
          <div id="teleport-layout-actions" class="inline-flex items-center space-x-4" />

          <UDropdown
            v-if="pageActions.length"
            class="mb-1.5 font-normal"
            :items="pageActions"
            :ui="{ container: 'z-50' }"
          >
            <UButton
              color="rnxblue"
              variant="solid"
              :label="$t('components.actions.actions')"
              trailing-icon="i-heroicons-chevron-down-20-solid"
              :ui="{ icon: { size: { sm: 'w-4 h-4' } } }"
            />
          </UDropdown>
        </div>
      </header>
      <div
        id="teleport-layout-tabs"
        class="sticky top-16 z-30 -mx-1 mb-4 bg-white px-1"
        :class="showTopBar ? '' : 'hidden'"
      />
      <NuxtPage />
    </UContainer>
  </div>
  <UModal
    :model-value="mainStore.isIdleSession"
    :open="mainStore.isIdleSession"
    :prevent-close="true"
    fullscreen
  >
    <div class="flex h-screen flex-col items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div class="rounded-lg bg-white p-8 shadow-lg dark:bg-gray-800">
        <NuxtImg
          provider="imagekit"
          class="mx-auto mb-6 pr-4"
          width="200"
          src="/zefire-spa/logo_renewex.svg"
        />
        <h2 class="mb-4 text-center text-2xl font-bold dark:text-gray-200">Session Timeout</h2>
        <p class="mb-6 text-center text-gray-600 dark:text-gray-400">
          Your session has expired due to inactivity. Please log in again.
        </p>
        <UButton class="mx-auto flex w-72" @click="() => login(auth0, route)">
          <span class="w-full text-center font-semibold">Log In</span>
        </UButton>
      </div>
    </div>
  </UModal>
  <div id="popup" />
  <div id="notifications" />
</template>
