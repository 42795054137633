import * as Sentry from "@sentry/vue"
import { CaptureConsole } from "@sentry/integrations"
import * as pkg from "~/package.json"

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (!config.public.sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    release: `zefire-front@${pkg.version}`,
    environment: config.public.sentry.env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
        tracingOrigins: ["localhost", "app.renewex.fun", "app.renewex.co", /^\//],
      }),
      new Sentry.Replay(),
      new CaptureConsole({ levels: ["log", "info", "warn", "error", "debug", "assert"] }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: config.public.sentry.tracesSampleRate,
    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: config.public.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: config.public.sentry.replaysOnErrorSampleRate,

    trackComponents: true,
    logErrors: true, // still log errors in console
  })
})
