import type { ComputedRef, Ref } from "vue"
import { computed } from "vue"
import * as dt from "date-fns"

import Fuse from "fuse.js"
import { z } from "zod"
import { MachineTechno, MachineType } from "./schemas"

// ______ Specific Models Filters ______

function sanitizeHorizons(horizonFilter: FilterDefinition<Date>[], periods: string[]) {
  // check if the input horizon actually belong to the accepted list
  return (
    periods.filter(
      (period) =>
        !R.isIncludedIn(
          period,
          horizonFilter.map((h) => h.label),
        ),
    ).length == 0
  )
}

// Contracts related
export const ContractFiltersCat = z.enum([
  "techno",
  "purchase_order",
  "horizon",
  "price_mechanism",
  "status",
  "counterpart",
  "assetowner",
  "invoice_submit_channel",
  "portfolio",
  "invoicing_automated",
])
export type ContractFiltersCat = z.infer<typeof ContractFiltersCat>
const _contractFilterRecords = {
  [ContractFiltersCat.enum.techno]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.purchase_order]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.horizon]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.price_mechanism]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.status]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.counterpart]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.assetowner]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.invoice_submit_channel]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.portfolio]: zodutils.strArray.default([]),
  [ContractFiltersCat.enum.invoicing_automated]: zodutils.strArray.default([]),
} satisfies Record<ContractFiltersCat, any>
export const ContractFilters = z.object(_contractFilterRecords)
export type ContractFilters = z.infer<typeof ContractFilters>

// Invoice related
export const InvoiceFiltersCat = z.enum([
  "status",
  "assetowner",
  "purchase_order",
  "site",
  "price_mechanism",
  "period",
  "external_status",
  "internal_id",
  "comment",
  "publication_id",
  "publication_date",
  "invoice_submit_channel",
  "portfolio",
  "is_accounted",
])
export type InvoiceFiltersCat = z.infer<typeof InvoiceFiltersCat>
const _invoiceFilterRecords = {
  [InvoiceFiltersCat.enum.status]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.assetowner]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.purchase_order]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.site]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.price_mechanism]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.period]: zodutils.strArray
    .default([])
    .refine((periods) => sanitizeHorizons(longPastHorizonWithMonthsFilter, periods)),
  [InvoiceFiltersCat.enum.external_status]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.internal_id]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.comment]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.publication_id]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.publication_date]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.invoice_submit_channel]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.portfolio]: zodutils.strArray.default([]),
  [InvoiceFiltersCat.enum.is_accounted]: zodutils.strArray.default([]),
} satisfies Record<InvoiceFiltersCat, any>
export const InvoiceFilters = z.object(_invoiceFilterRecords)
export type InvoiceFilters = z.infer<typeof InvoiceFilters>

// Site related
export const missingPortfolioLabel = "no_portfolio"
export const latePastillaStatus = "late"

export const SiteFiltersCat = z.enum([
  "techno",
  "contract_status",
  "assetowner",
  "card",
  "portfolio",
  "internal_id",
  "data_origins",
  "power_production_status",
  "availability_status",
  "is_external",
  "status",
  "network_voltage",
  "commissioning",
  "prm",
])
export type SiteFiltersCat = z.infer<typeof SiteFiltersCat>

export const IsExternalChoices = z.enum(["internal", "external"])
export type IsExternalChoices = z.infer<typeof IsExternalChoices>

export const IsExternalSchema = zodutils.strArray.default([])
export type IsExternalSchema = z.infer<typeof IsExternalSchema>

export function isExternalChoiceToQueryParam(is_external: IsExternalSchema): null | boolean {
  const mapIsExternalBool: { [key: string]: boolean } = {
    [IsExternalChoices.enum.internal]: false,
    [IsExternalChoices.enum.external]: true,
  }
  if (is_external.length !== 1) {
    return null
  } else {
    return mapIsExternalBool[is_external[0]]
  }
}

const _baseSiteFilterCat = SiteFiltersCat.exclude(["is_external", "status"])
type _baseSiteFilterCat = z.infer<typeof _baseSiteFilterCat>
const _siteFilterRecords = {
  [SiteFiltersCat.enum.techno]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.contract_status]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.assetowner]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.power_production_status]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.availability_status]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.card]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.portfolio]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.internal_id]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.data_origins]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.commissioning]: zodutils.strArray
    .default([])
    .refine((periods) => sanitizeHorizons(longPastHorizonWithMonthsFilter, periods)),
  [SiteFiltersCat.enum.network_voltage]: zodutils.strArray.default([]),
  [SiteFiltersCat.enum.prm]: zodutils.strArray.default([]),
} satisfies Record<_baseSiteFilterCat, any>
export const SiteFilters = z.object(_siteFilterRecords)
export type SiteFilters = z.infer<typeof SiteFilters>

export const SiteFiltersFull = SiteFilters.extend({
  [SiteFiltersCat.enum.is_external]: IsExternalSchema,
  [SiteFiltersCat.enum.status]: zodutils.strArray
    .nullable()
    .default([SiteStatus.enum.commissioned]),
})
export type SiteFiltersFull = z.infer<typeof SiteFiltersFull>

// __________ Supervision Related __________

export const CompanyFiltersSupervisionCat = z.enum(["portfolio", "assetowner", "is_external"])
export type CompanyFiltersSupervisionCat = z.infer<typeof CompanyFiltersSupervisionCat>
const _companyFilterRecords = {
  [CompanyFiltersSupervisionCat.enum.portfolio]: zodutils.strArray.default([]),
  [CompanyFiltersSupervisionCat.enum.assetowner]: zodutils.strArray.default([]),
  [CompanyFiltersSupervisionCat.enum.is_external]: zodutils.strArray.default([]),
} satisfies Record<CompanyFiltersSupervisionCat, any>
export const CompanyFiltersSupervision = z.object(_companyFilterRecords)
export type CompanyFiltersSupervision = z.infer<typeof CompanyFiltersSupervision>

// __________ GSKpis Related __________

export const GSKpiFilterCat = z.enum([
  "technos",
  "assetowner",
  "holding",
  "is_external",
  "portfolios",
  "regions",
])
export type GSKpiFilterCat = z.infer<typeof GSKpiFilterCat>
const _GSKpiFilterRecords = {
  [GSKpiFilterCat.enum.technos]: z
    .preprocess(zodutils.ensureArray, z.array(z.union([MachineTechno, MachineType])))
    .default([]),
  [GSKpiFilterCat.enum.assetowner]: zodutils.strArray.default([]),
  [GSKpiFilterCat.enum.holding]: zodutils.strArray.default([]),
  [GSKpiFilterCat.enum.is_external]: zodutils.strArray.default([]),
  [GSKpiFilterCat.enum.portfolios]: zodutils.strArray.default([]),
  [GSKpiFilterCat.enum.regions]: zodutils.strArray.default([]),
} satisfies Record<GSKpiFilterCat, any>
export const GSKpiFilters = z.object(_GSKpiFilterRecords)
export type GSKpiFilters = z.infer<typeof GSKpiFilters>

// __________ Document Related __________

export const DocumentFiltersCat = z.enum([
  "type",
  "target",
  "extension",
  "creation_date",
  "last_update",
])
export type DocumentFiltersCat = z.infer<typeof DocumentFiltersCat>
const _documentFilterRecords = {
  [DocumentFiltersCat.enum.type]: zodutils.strArray.default([]),
  [DocumentFiltersCat.enum.target]: zodutils.strArray.default([]),
  [DocumentFiltersCat.enum.extension]: zodutils.strArray.default([]),
  [DocumentFiltersCat.enum.creation_date]: zodutils.strArray
    .default([])
    .refine((periods) => sanitizeHorizons(longPastHorizonWithMonthsFilter, periods)),
  [DocumentFiltersCat.enum.last_update]: zodutils.strArray
    .default([])
    .refine((periods) => sanitizeHorizons(longPastHorizonWithMonthsFilter, periods)),
} satisfies Record<DocumentFiltersCat, any>
export const DocumentFilters = z.object(_documentFilterRecords)
export type DocumentFilters = z.infer<typeof DocumentFilters>

// __________ Note  Related __________

export const NoteFiltersCat = z.enum([
  "target",
  "tags",
  "creation_date",
  "last_update",
  "created_by",
  "archived",
  "pinned",
])
export type NoteFiltersCat = z.infer<typeof NoteFiltersCat>

export const IsPinnedChoices = z.enum(["Pinned"])
export type IsPinnedChoices = z.infer<typeof IsArchivedChoices>

export const IsPinnedSchema = zodutils.strArray.default([])
export type IsPinnedSchema = z.infer<typeof IsPinnedSchema>

export const IsArchivedChoices = z.enum(["Archived"])
export type IsArchivedChoices = z.infer<typeof IsArchivedChoices>

export const IsArchivedSchema = zodutils.strArray.default([])
export type IsArchivedSchema = z.infer<typeof IsArchivedSchema>

const _baseNoteFilterCat = NoteFiltersCat.exclude(["archived", "pinned"])
type _baseNoteFilterCat = z.infer<typeof _baseNoteFilterCat>

const _noteFilterRecords = {
  [NoteFiltersCat.enum.target]: zodutils.strArray.default([]),
  [NoteFiltersCat.enum.tags]: zodutils.strArray.default([]),
  [NoteFiltersCat.enum.creation_date]: zodutils.strArray.default([]),
  [NoteFiltersCat.enum.last_update]: zodutils.strArray.default([]),
  [NoteFiltersCat.enum.created_by]: zodutils.strArray.default([]),
} satisfies Record<_baseNoteFilterCat, any>
export const NoteFilters = z.object(_noteFilterRecords)
export type NoteFilters = z.infer<typeof NoteFilters>

export const NoteFiltersFull = NoteFilters.extend({
  [NoteFiltersCat.enum.archived]: IsArchivedSchema,
  [NoteFiltersCat.enum.pinned]: IsPinnedSchema,
})
export type NoteFiltersFull = z.infer<typeof NoteFiltersFull>

// __________ Event Definition __________

export const IsValidatedChoices = z.enum(["validated", "not_validated", "both"])
export type IsValidatedChoices = z.infer<typeof IsValidatedChoices>
export const IsValidatedSchema = IsValidatedChoices.nullish().default("not_validated")
export type IsValidatedSchema = z.infer<typeof IsValidatedSchema>

export const DurationChoices = z.enum([
  "above_month",
  "above_week",
  "above_day",
  "above_4_hours",
  "above_2_hours",
  "above_hour",
  "below_hour",
  "below_day",
])
export type DurationChoices = z.infer<typeof DurationChoices>
export const DurationSchema = DurationChoices.nullish().default("above_4_hours")
export type DurationSchema = z.infer<typeof DurationSchema>

export const EventStatusChoices = z.enum([
  "not_validated_downgraded",
  "not_validated_stopped",
  "validated_downgraded",
  "validated_stopped",
  "ai",
  "delayed_events",
  "info",
])
export type EventStatusChoices = z.infer<typeof EventStatusChoices>
export const EventStatusSchema = z.array(EventStatusChoices).nullish().default([])
export type EventStatusSchema = z.infer<typeof EventStatusSchema>

export const EventFiltersCat = z.enum([
  "portfolio",
  "assetowner",
  "machine_type",
  "network_voltage",
  "status",
  "validated",
  "duration",
  "is_external",
])
export type EventFiltersCat = z.infer<typeof EventFiltersCat>
const _eventFilterRecords = {
  [EventFiltersCat.enum.portfolio]: zodutils.strArray.default([]),
  [EventFiltersCat.enum.assetowner]: zodutils.strArray.default([]),
  [EventFiltersCat.enum.machine_type]: zodutils.strArray.default([]),
  [EventFiltersCat.enum.network_voltage]: zodutils.strArray.default([]),
  [EventFiltersCat.enum.is_external]: zodutils.strArray.default([]),
  [EventFiltersCat.enum.status]: EventStatusSchema,
  [EventFiltersCat.enum.validated]: IsValidatedSchema,
  [EventFiltersCat.enum.duration]: DurationSchema,
} satisfies Record<EventFiltersCat, any>
export const EventFilters = z.object(_eventFilterRecords)
export type EventFilters = z.infer<typeof EventFilters>

// __________ Datahealth Definition __________

export const DHSiteFilterCat = z.enum(["is_external", "commissioning"])
export type DHSiteFilterCat = z.infer<typeof DHSiteFilterCat>

export const SiteFiltersT = z.object({
  [DHSiteFilterCat.enum.is_external]: zodutils.strArray.default([]),
  [DHSiteFilterCat.enum.commissioning]: zodutils.strArray.default([]),
})
export type SiteFiltersT = z.infer<typeof SiteFiltersT>

type SiteFilterFn = (
  label: string,
) => (o: { site: { commissioning?: Date | undefined | null; is_external: boolean } }) => boolean

export const mapCategoryFn: {
  [key in DHSiteFilterCat]: SiteFilterFn
} = {
  [DHSiteFilterCat.enum.is_external]: (label) => (o) =>
    o.site.is_external ===
    { [IsExternalChoices.enum.internal]: false, [IsExternalChoices.enum.external]: true }[label],
  [DHSiteFilterCat.enum.commissioning]: (label) => (o) => {
    if (label === unsetLabel) {
      return R.isNullish(o.site.commissioning)
    } else {
      const fn = R.find(longPastHorizonWithMonthsFilter, (flt) => flt.label === label)!.fn
      return R.isNonNullish(o.site.commissioning) && fn(o.site.commissioning)
    }
  },
}

export function getFiltersSelectOpts(t: (value: string) => string): {
  [key in DHSiteFilterCat]: FilterSelectOptions
} {
  return {
    [DHSiteFilterCat.enum.is_external]: {
      label: t("title.internal_external"),
      isSearchable: false,
      choices: IsExternalChoices.options,
      formatter: (item: string) => t(`site.is_external.${item}`),
    },
    [DHSiteFilterCat.enum.commissioning]: {
      label: t("title.commissioning"),
      choices: [unsetLabel, ...R.map(longPastHorizonWithMonthsFilter, (flt) => flt.label)],
      formatter: (item: string) =>
        R.isIncludedIn(
          item,
          longPastHorizonFilter.map((x) => x.label),
        )
          ? t(`time.${item}`)
          : item == unsetLabel
            ? t("title.unset_label")
            : item,
    },
  }
}

export const mapBadgeColor: Partial<{ [key in DHSiteFilterCat]: string }> = {
  [DHSiteFilterCat.enum.is_external]: "indigo",
}

export function siteNameSearchMapper(item: { site: { name: string } }) {
  return {
    name: item.site.name,
  }
}

// __________ Marketplace Definition __________

export const MarketplaceFilterCat = z.enum(["kind", "registration_number", "vat_number"])
export type MarketplaceFilterCat = z.infer<typeof MarketplaceFilterCat>
const _marketplaceFilterRecords = {
  [MarketplaceFilterCat.enum.kind]: zodutils.strArray.default([]),
  [MarketplaceFilterCat.enum.registration_number]: zodutils.strArray.default([]),
  [MarketplaceFilterCat.enum.vat_number]: zodutils.strArray.default([]),
} satisfies Record<MarketplaceFilterCat, any>
export const MarketplaceFilter = z.object(_marketplaceFilterRecords)
export type MarketplaceFilter = z.infer<typeof MarketplaceFilter>

// ______ Filter Definition ______

export interface FilterSelectOptions {
  choices: string[]
  label?: string
  style?: string
  isSearchable?: boolean
  keepWhenEmptyChoices?: boolean
  formatter?: string | ((value: string) => string) | null
}

export interface FilterDefinition<T> {
  label: string // the label coming from user selection
  fn: (o: T) => boolean // should return True if want to display
  category?: string // the category of the Filter for later use in groupBy
  extra?: any // optional extra infos (to filter on sub-category for example)
}

export type Filters = Record<string, string[] | string | null | undefined>

export function toFilterDefinitions<Model, F extends Filters = Filters>(
  filters: Ref<F>,
  mapCategoryFn: { [key in keyof Filters]: (label: string) => (o: Model) => boolean },
): ComputedRef<FilterDefinition<Model>[]> {
  return computed(() => {
    const filtersDefs: FilterDefinition<Model>[] = []
    ld.forEach(Object.keys(filters.value), (category: string) => {
      const value = filters.value[category]
      const fnForCategory = mapCategoryFn[category]
      if (Array.isArray(value)) {
        ld.forEach(value, (label: string) =>
          filtersDefs.push({ label, category, fn: fnForCategory(label) }),
        )
      } else if (value != null) {
        const label = value
        filtersDefs.push({ label, category, fn: fnForCategory(label) })
      }
    })
    return filtersDefs
  })
}

export function applyFilters<T extends Record<string, any>>(
  objects: T[],
  filters: FilterDefinition<T>[],
  childrenKey?: keyof T | string,
): T[] {
  // Apply filters as:
  // - an Union for the same 'category' (if any filter for the same category returns true, then we select the object)
  // - a Set accross categorys (only if all the aggregated results accross categorys returns all true, we return the object)

  if (filters.length === 0) {
    return objects
  }

  const mapFilters = R.groupBy(filters, (e) => e.category)

  const filtered = R.filter(objects, (o) =>
    Object.values(mapFilters)
      .map((filtersForCategory) => filtersForCategory.map((flt) => flt.fn(o)).some(Boolean))
      .every(Boolean),
  )

  if (childrenKey) {
    filtered.forEach((o) => {
      if (R.isArray(o[childrenKey]) && o[childrenKey]) {
        // @ts-expect-error don't know how to handle recursive types
        o[childrenKey] = applyFilters(o[childrenKey], filters, childrenKey)
      }
    })
  }

  return filtered
}

// ______ Duration related Filter Definitions ______
const now = (): Date => new Date()

function getDuration(opts: Duration) {
  return dt.differenceInMilliseconds(dt.add(now(), opts), now())
}

export const durationFilter: FilterDefinition<{ startDate: Date; endDate: Date }>[] = [
  {
    label: DurationChoices.enum.above_month,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ months: 1 })
    },
  },
  {
    label: DurationChoices.enum.above_week,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ weeks: 1 })
    },
  },
  {
    label: DurationChoices.enum.above_day,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ days: 1 })
    },
  },
  {
    label: DurationChoices.enum.above_4_hours,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ hours: 4 })
    },
  },
  {
    label: DurationChoices.enum.above_2_hours,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ hours: 2 })
    },
  },
  {
    label: DurationChoices.enum.above_hour,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) > getDuration({ hours: 1 })
    },
  },
  {
    label: DurationChoices.enum.below_hour,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) < getDuration({ hours: 1 })
    },
  },
  {
    label: DurationChoices.enum.below_day,
    fn: (data) => {
      return dt.differenceInMilliseconds(data.endDate, data.startDate) <= getDuration({ days: 1 })
    },
  },
]

// ______ Date related Filter Definitions ______

export const shortHorizonFilter: FilterDefinition<Date>[] = [
  {
    label: "today",
    fn: (date) => dt.startOfToday() <= date && date < dt.startOfTomorrow(),
  },
  {
    label: "tomorrow",
    fn: (date) => dt.startOfTomorrow() <= date && date < dt.startOfDay(dt.addDays(now(), 2)),
  },
  {
    label: "yesterday",
    fn: (date) => dt.startOfDay(dt.addDays(now(), -1)) <= date && date < dt.startOfToday(),
  },
  {
    label: "current_week",
    fn: (date) => dt.startOfWeek(now()) <= date && date < dt.addDays(dt.startOfWeek(now()), 7),
  },
  {
    label: "current_month",
    fn: (date) => dt.startOfMonth(now()) <= date && date < dt.addMonths(dt.startOfMonth(now()), 1),
  },
  {
    label: "next_7_days",
    fn: (date) => now() <= date && date < dt.addDays(now(), 7),
  },
  {
    label: "next_15_days",
    fn: (date) => now() <= date && date < dt.addDays(now(), 15),
  },
  {
    label: "next_30_days",
    fn: (date) => now() <= date && date < dt.addDays(now(), 30),
  },
  {
    label: "last_7_days",
    fn: (date) => dt.addDays(now(), -7) <= date && date < now(),
  },
  {
    label: "last_15_days",
    fn: (date) => dt.addDays(now(), -15) <= date && date < now(),
  },
  {
    label: "last_30_days",
    fn: (date) => dt.addDays(now(), -30) <= date && date < now(),
  },
]

export const longPastHorizonFilter: FilterDefinition<Date>[] = [
  {
    label: "today",
    fn: (date) => dt.startOfToday() <= date && date < dt.startOfTomorrow(),
  },
  {
    label: "current_month",
    fn: (date) => dt.startOfMonth(now()) <= date && date < dt.addMonths(dt.startOfMonth(now()), 1),
  },
  {
    label: "this_quarter",
    fn: (date) => date > dt.startOfQuarter(now()),
  },
  {
    label: "last_quarter",
    fn: (date: Date): boolean => {
      const lastQuarterStart = dt.startOfQuarter(dt.addDays(dt.startOfQuarter(now()), -1))
      return date > lastQuarterStart && date < dt.startOfQuarter(now())
    },
  },
  {
    label: "last_6_months",
    fn: (date) => dt.addMonths(now(), -6) <= date && date < now(),
  },
  {
    label: "this_year",
    fn: (date) => date >= dt.startOfYear(now()),
  },
  {
    label: "last_year",
    fn: (date: Date): boolean => {
      const lastYearStart = dt.startOfYear(dt.addYears(now(), -1))
      return date <= dt.startOfYear(now()) && date >= lastYearStart
    },
  },
  {
    label: "last_2_years",
    fn: (date) => dt.addYears(now(), -2) <= date && date < now(),
  },
  {
    label: "before_year",
    fn: (date) => date <= dt.startOfYear(now()),
  },
]

export const longFutureHorizonFilter: FilterDefinition<Date>[] = [
  {
    label: "next_6_months",
    fn: (date) => dt.startOfMonth(now()) <= date && date < dt.addMonths(dt.startOfMonth(now()), 6),
  },
  {
    label: "next_year",
    fn: (date) =>
      dt.startOfYear(new Date()) <= date && date < dt.addYears(dt.startOfYear(new Date()), 1),
  },
  {
    label: "next_2_years",
    fn: (date) =>
      dt.startOfYear(new Date()) <= date && date < dt.addYears(dt.startOfYear(new Date()), 2),
  },
]

function getLastNmonths(n: number = 6, offset: number = 1): FilterDefinition<Date>[] {
  const lastMonths: FilterDefinition<Date>[] = []
  for (let i = offset; i <= n; i++) {
    const month = dt.addMonths(dt.startOfMonth(new Date()), -i)
    lastMonths.push({
      label: dt.format(month, "MMMM Y"),
      fn: (date) => month <= date && date < dt.addMonths(month, 1),
    })
  }
  return lastMonths
}

export const longPastHorizonWithMonthsFilter: FilterDefinition<Date>[] = [
  // Add first 3 past months
  ...getLastNmonths(3, 1),
  // Add past horizon filter
  ...longPastHorizonFilter,
  // Add last 6-3 past months
  ...getLastNmonths(6, 4),
]

// ______ Fuzzy Search ______

type SearchMapper<T> = (map: T) => any

export function applySearch<T>(
  objects: T[],
  search: string,
  mapper: SearchMapper<T>,
  threshold = 0.4,
): T[] {
  if (objects.length === 0) {
    return []
  }

  const searchable = ld.map(objects, mapper)

  const keys = Object.keys(searchable[0])

  const fuse = new Fuse(searchable, {
    threshold,
    includeScore: true,
    keys,
  })

  const matches = fuse.search(search)
  const matchIndexes = ld.map(matches, "refIndex")

  // apply pullat on copy of array to avoid modification on objects
  return ld.pullAt(objects.slice(), matchIndexes)
}

export const unsetLabel = "unset_label"
