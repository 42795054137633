import { Scope, useMainStore } from "#imports"

export function hasScope(...scopes: Scope[]): boolean {
  const permissions = useMainStore().user?.permissions || []

  return scopes.every((scope) => permissions.includes(scope))
}

export function filterDropdownItemOnScope<DropdownItemT>(
  itemsScopes: (DropdownItemT & { scope?: Scope })[][],
): DropdownItemT[][] {
  return R.map(itemsScopes, (items) =>
    items
      .filter((item) => item.scope === undefined || hasScope(item.scope))
      .map((item) => R.pickBy(item, (_, key) => key !== "scope") as DropdownItemT),
  )
}
