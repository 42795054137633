import { useStorage } from "@vueuse/core"

import { createAuth0 } from "@auth0/auth0-vue"
import type { RouteLocationNormalized } from "vue-router"
import type { Auth0VueClient } from "@auth0/auth0-vue"

export enum AuthRoutePaths {
  login = "/auth/login",
  logout = "/auth/logout",
  callback = "/auth/callback",
}

function safeRequestedUrl(value: string | undefined | null): string {
  const fallback_route = "/"
  let requestedUrl = value || fallback_route
  if (Object.values(AuthRoutePaths).includes(requestedUrl as AuthRoutePaths)) {
    requestedUrl = fallback_route
  }
  return requestedUrl
}

// just store some info to ease re-connect
const auth0Metadata = useStorage<{
  org_id?: string
  connection?: string
}>("auth0-metadata-zefire", { org_id: undefined, connection: undefined })

export async function login(auth0: Auth0VueClient, route: RouteLocationNormalized): Promise<void> {
  const fn = auth0.loginWithRedirect
  if (route.query && route.query.invitation && route.query.organization) {
    // Handling invitation
    await fn({
      authorizationParams: {
        invitation: route.query.invitation as string,
        organization: route.query.organization as string,
      },
    })
  } else {
    const requestedUrl = safeRequestedUrl(route.fullPath)
    await fn({
      appState: { requestedUrl },
      authorizationParams: {
        organization: auth0Metadata.value.org_id,
        connection: auth0Metadata.value.connection,
      },
      async openUrl(url) {
        await navigateTo(url, { external: true })
      },
    })
  }
}

export async function logout(auth0: Auth0VueClient, opts?: { switchToOrgId: string }) {
  if (opts?.switchToOrgId) {
    auth0Metadata.value.org_id = opts.switchToOrgId
  } else {
    auth0Metadata.value = null
  }
  await auth0.logout({ logoutParams: { returnTo: window.location.origin } })
}

export async function handleCallback(auth0: Auth0VueClient): Promise<string> {
  const { appState } = await auth0.handleRedirectCallback()

  if (!auth0.isAuthenticated.value) {
    throw new Error("Failed to auth")
  }

  const user = auth0.user.value!
  const claims = auth0.idTokenClaims.value!

  auth0Metadata.value = {
    org_id: user.org_id || undefined,
    connection: claims["https://renewex.co"].connection || undefined,
  }

  // appState is undefined in case of invitation
  return safeRequestedUrl(appState?.requestedUrl as any)
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const auth0 = createAuth0(
    {
      domain: config.public.auth0.domain,
      clientId: config.public.auth0.clientId,
      cacheLocation: "localstorage",
      useRefreshTokens: true,
      authorizationParams: {
        display: "page",
        max_age: config.public.auth0.max_age,
        audience: config.public.auth0.audience,
        redirect_uri: `${window.location.origin}${AuthRoutePaths.callback}`,
      },
    },
    {
      skipRedirectCallback: true,
      errorPath: "/error",
    },
  )

  if (import.meta.client) {
    nuxtApp.vueApp.use(auth0)
  }
})
