import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    directive: "tippy", // => v-tippy
    component: "tippy", // => <tippy/>
    defaultProps: {
      theme: "rnx-dark",
      arrow: true,
      allowHTML: true,
    },
    flipDuration: 0,
  })
})
