
import * as imagekitRuntime$ueJBtmmQ7J from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.3.0_rollup@3.29.4/node_modules/@nuxt/image/dist/runtime/providers/imagekit'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imagekit",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imagekit']: { provider: imagekitRuntime$ueJBtmmQ7J, defaults: {"baseURL":"https://ik.imagekit.io/9c2qah8ie"} }
}
        