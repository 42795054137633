import type { ZodObject, ZodRawShape } from "zod"
import { z } from "zod"
import * as R from "remeda"

function ensureArray<T>(arg: T | T[]): T[] {
  if (!R.isDefined(arg)) {
    return []
  } else if (!R.isArray(arg)) {
    return [arg]
  } else {
    return arg
  }
}

const strArray = z.preprocess(ensureArray, z.array(z.string()).default([]))

function parseObjDefaultFallback<T extends ZodRawShape>(schema: ZodObject<T>, values: any) {
  const result = schema.safeParse(values)
  if (!result.success) {
    const failedPaths = result.error.issues.map((issue) => issue.path).flat()
    const failedPathsAsDefault = failedPaths.reduce(
      (agg, path) => ({ ...agg, [path]: undefined }),
      {},
    )
    return schema.parse({ ...values, ...failedPathsAsDefault })
  } else {
    return result.data
  }
}

export const zodutils = {
  ensureArray,
  strArray,
  parseObjDefaultFallback,
} as const
