import NProgress from "nprogress"
import "nprogress/nprogress.css"

NProgress.configure({ minimum: 0.1, easing: "ease", showSpinner: false })

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hooks.hook("page:start", () => {
    NProgress.start()
  })
  nuxtApp.hooks.hook("page:finish", () => {
    NProgress.done()
  })
})
